.index-facilities {

	.owl-carousel {
		aspect-ratio: 16/9;

		.owl-nav {
			button {
				width: 40px;
				height: 40px;
				top: 45%;
				position: absolute;
				border-radius: 50%;
				cursor: pointer;
				opacity: 1;
				color: var(--color-white);
				text-align: center;
				background-color: rgba(112, 112, 113, 0.7) !important;
			}

			.owl-prev {
				left: 25px;

				span {
					font-weight: bold;
					line-height: 40px;
					font-size: 25px;
					line-height: 1;
					position: relative;
					top: -1px;
				}

			}

			.owl-next {
				right: 25px;

				span {
					font-weight: bold;
					line-height: 40px;
					font-size: 25px;
					line-height: 1;
					position: relative;
					top: -1px;
				}

			}
		}

		.owl-dots {
			position: absolute;
			width: 70%;
			bottom: 10px;
			padding: 0;
			margin: 0;
			list-style: none;
			text-align: center;
			line-height: 1;
			left: 50%;
			transform: translateX(-50%);

			.owl-dot {
				background-color: #fff;
				width: 6px !important;
				height: 6px !important;
				opacity: .25;
				margin: 0 1px !important;
				border-radius: 50%;

				&.active {
					opacity: 1;
				}
			}
		}

		.fac-picture {
			position: relative;
			overflow: hidden;
			aspect-ratio: 16/9;

			@media (max-width:767px) {
				min-height: 350px;
			}

			img {
				object-fit: cover;
			}

			.content {
				position: absolute;
				bottom: 0px;
				left: 0px;
				width: 100%;
				background: rgba(80, 80, 80, 0.75);
				color: var(--color-white);
				z-index: 1;
				padding: 10px 10px;
				font-weight: bold;
			}

			.title {
				font-size: 24px;
			}
		}
	}





}