.cuxhaven-erleben-v1 {

	margin-top: var(--page-margin-top);

	.best-webcams {
		h3 {
			margin-bottom: 15px;
		}

		img {
			width: 100%;
		}
	}
}