.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	@media (max-width: 767px) {
		margin-top: 175px;
	}

	#requestModal {
		right: 0;
		margin: 0 auto;
		padding: 0 !important;
		left: 0;

		.modal-body {
			height: 65vh;
			overflow: auto;


		}
	}

	.left-col {
		padding: 0px 15px;


		@media(max-width: 1200px) {
			width: 100% !important;
		}


		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}


	.right-col {

		padding: 0px 15px;


		@media(max-width: 1200px) {
			display: none;
		}


		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}

	.booking-hint {
		font-size: 20px;
		padding-bottom: 10px;
		text-align: center;

		&.active {
			color: red;
		}
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: block;
			margin-bottom: 57px;
			margin-top: 20px;
		}


		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
			display: none;
		}


	}

	.bedswarning {
		display: none;
	}
}