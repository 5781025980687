.regionen-v1 {
	margin-top: var(--page-margin-top);

	@media (min-width:768px) {
		.region-text {
			min-height: 100px;
			display: block;
		}
	}

	.img-content {
		height: 225px;
		display: block;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}