.agb-v1 {
	margin-top: 140px;

	@media (max-width: 768px) {
		margin-top: 170px;
	}

	h1 {
		@media (max-width: 480px) {
			font-size: 28px;
		}
	}

	h2 {
		@media (max-width: 480px) {
			font-size: 24px;
		}
	}

	.inner-banner {
		background-image: url(RESOURCE/img/banner-1.jpg);
	}
}