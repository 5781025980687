@import "mixin.css";

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.flex {
	@mixin flex;
}

.size100 {
	@mixin size100;
}

.img-responsive {
	max-width: 100%;
}

a {
	pointer-events: unset !important;
}

html,
body {
	font-family: var(--font-family-main);
	color: var(--font-color-main);
	font-size: var(--font-size-main);
	font-weight: var(--font-weight-main);
	font-size: 18px;
}

.btn {
	font-family: var(--font-family-title);
}

html {
	scroll-padding-top: 200px;
}

.facility-bl {
	background-color: var(--color-grey-light);

	.facility-li {
		background-color: var(--color-white);
		padding: 15px;
	}

	.region-bl {
		a {
			text-decoration: none;
		}
	}
}

h1,
.h1 {
	font-family: var(--h1-font-family);
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	color: var(--h1-font-color);
	text-transform: var(--h1-text-transform);
	text-decoration: var(--h1-text-decoration);
	font-family: var(--font-family-title);

}

.h1-width-small {
	line-height: var(--h1-font-size);

	small {
		font-size: var(--font-size-main);
		text-transform: none;
		display: inline-block;
	}
}

h2,
.h2 {
	font-family: var(--h2-font-family);
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	color: var(--h2-font-color);
	text-transform: var(--h2-text-transform);
	text-decoration: var(--h2-text-decoration);
	font-family: var(--font-family-title);
}

h3,
.h3 {
	font-family: var(--h3-font-family);
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	color: var(--h3-font-color);
	text-transform: var(--h3-text-transform);
	text-decoration: var(--h3-text-decoration);
	font-family: var(--font-family-title);
}

h4,
.h4 {
	font-family: var(--h4-font-family);
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	color: var(--h4-font-color);
	text-transform: var(--h4-text-transform);
	text-decoration: var(--h4-text-decoration);
	font-family: var(--font-family-title);
}

h5,
.h5 {
	font-family: var(--h5-font-family);
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	color: #8c8c8c;
	text-transform: var(--h5-text-transform);
	text-decoration: var(--h5-text-decoration);
	font-family: var(--font-family-title);
}

.heading1 {
	display: inline-block;
	/*border-bottom: 1px solid var(--heading1-border-color);*/
	margin-bottom: var(--heading1-margin-bottom);
	text-transform: var(--heading1-text-transform);
	font-family: var(--font-family-title);
	font-size: 30px;
	margin-top: 20px;
}

.maintext {
	margin: 10px;
	font-size: 20px;
	line-height: 32px;
}

a {
	color: var(--link-font-color);
	text-decoration: var(--link-text-decoration);

	&:hover {
		text-decoration: var(--link-text-decoration-hover);
		color: var(--link-font-color-hover);
	}
}

ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

p {
	color: #8c8c8c;
	font-size: 18px;
}

.form-control:focus {
	box-shadow: none;
}

.btn:focus,
.btn:active {
	box-shadow: none;
}

@font-face {
	font-family: 'Ubuntu-Regular';
	src: url('RESOURCE/fonts/Ubuntu-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg'),
		url('RESOURCE/fonts/Ubuntu-Regular.eot'),
		url('RESOURCE/fonts/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Regular.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'OpenSans-CondLight';
	src: url('RESOURCE/fonts/OpenSans-CondLight.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-CondLight.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-CondLight.svg#OpenSans-CondLight') format('svg'),
		url('RESOURCE/fonts/OpenSans-CondLight.eot'),
		url('RESOURCE/fonts/OpenSans-CondLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-CondLight.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu-Bold';
	src: url('RESOURCE/fonts/Ubuntu-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg'),
		url('RESOURCE/fonts/Ubuntu-Bold.eot'),
		url('RESOURCE/fonts/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Bold.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Smooch-Regular';
	src: url('RESOURCE/fonts/Smooch-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Smooch-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Smooch-Regular.svg#Smooch-Regular') format('svg'),
		url('RESOURCE/fonts/Smooch-Regular.eot'),
		url('RESOURCE/fonts/Smooch-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Smooch-Regular.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/*--- background-colors---*/

.bg-primary {
	background-color: var(--color-primary);
}

.bg-primary-light {
	background-color: var(--color-primary-light);
}

.bg-secondary {
	background-color: var(--color-secondary);
}

.bg-secondary-light {
	background-color: var(--color-secondary-light);
}

.bg-white {
	background-color: var(--color-white);
}

.bg-grey-light {
	background-color: var(--color-grey-light);
}

.bg-grey-normal {
	background-color: var(--color-grey-normal);
}

.bg-grey-dark {
	background-color: var(--color-grey);
}

.bg-black {
	background-color: var(--color-black);
}

/*--- font-colors---*/

.color-primary {
	color: var(--color-primary) !important;
}

.color-primary-light {
	color: var(--color-primary-light) !important;
}

.color-secondary {
	color: var(--color-secondary) !important;
}

.color-secondary-light {
	color: var(--color-secondary-light) !important;
}

.color-white {
	color: var(--color-white) !important;
}

.color-grey-light {
	color: var(--color-grey-light) !important;
}

.color-grey-normal {
	color: var(--color-grey-normal) !important;
}

.color-grey-dark {
	color: var(--color-grey) !important;
}



.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

/*--- Datepicker ---*/

.datepicker-trigger {

	.asd__day--disabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}


	.asd__day--disabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled {
		background-color: var(--cal-available) !important;
		color: var(--cal-text-color) !important;

		&.asd__day--disabled {
			background-color: var(--cal-disabled) !important;
			opacity: 0.8 !important;
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--cal-selected) !important;
			}

			&.asd__day--in-range {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			}

			&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			}
		}


	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			background: var(--cal-selected) !important;
			opacity: 1 !important;
			color: var(--cal-text-color-hover) !important;
		}
	}

	.asd__day--selected {
		background: var(--cal-selected) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				left: 0px;
			}
		}

	}
}

/*--- Buttons ---*/

.btn {
	border-radius: var(--btn-border-radius);
	font-size: var(--btn-font-size);
	/*	padding: 8px 16px; */
	text-transform: var(--btn-text-transform);

	&.btn-primary,
	&.btn-go {
		background-color: var(--btn-primary-bg-color);
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}
	}

	&.btn-secondary {
		background-color: var(--btn-secondary-bg-color);
		color: var(--btn-secondary-font-color);
		border-color: var(--btn-secondary-border-color);

		&:hover {
			background-color: var(--btn-secondary-bg-color-hover);
			border-color: var(--btn-secondary-border-color-hover);
			color: var(--btn-secondary-font-color-hover);
		}
	}

	&.btn-default {
		background-color: var(--btn-default-bg-color);
		color: var(--btn-default-font-color);
		border-color: var(--btn-default-border-color);

		&:hover {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}
	}

	&.btn-add {
		background-color: var(--btn-add-bg-color);
		color: var(--btn-add-font-color);
		border-color: var(--btn-add-border-color);

		&:hover {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}
	}

	&.btn-remove {
		background-color: var(--btn-remove-bg-color);
		color: var(--btn-remove-font-color);
		border-color: var(--btn-remove-border-color);

		&:hover {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}
	}

	&.btn-bc {
		background-color: var(--btn-breadcrumb-bg-color);
		color: var(--btn-breadcrumb-font-color);
		border-color: var(--btn-breadcrumb-border-color);

		&:hover {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}
	}

	&.btn-sm {
		font-size: var(--font-size-sm);
		padding: 6px 12px;
	}

	&.btn-lg {
		height: 46px;
		font-size: var(--font-size-lg);
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			min-width: 160px;
		}

		.fa {
			opacity: .4;
			margin-left: 15px;
		}
	}
}

.breadcrumb {
	background-color: #fff !important;
	padding-left: 0px;
	padding-bottom: 0;

}

.btn-breadcrumb .btn:not(:last-child):after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid rgb(173, 173, 173);
	position: absolute;
	top: 50%;
	margin-top: -17px;
	margin-left: 1px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn {
	padding: 6px 12px 6px 24px;

	&.disabled {
		opacity: 0.6;
		color: #000;

	}
}

.btn-breadcrumb .btn:first-child {
	padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
	padding: 6px 18px 6px 24px;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}



.breadcrumb li a:hover:after {
	border-left-color: #ffc107 !important;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		@media (max-width:576px) {
			top: 20%;
		}




		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--color-primary) !important;
			position: absolute;
			left: 0;

			@media (max-width:991px) and (min-width:600px) {
				left: -30px;
			}

			@media (min-width:1060px) {
				left: -50px;
			}


			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--color-white);
					font-family: "Font Awesome 5 Free";
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;

				@media (max-width:991px) and (min-width:600px) {
					right: -30px;
				}

				@media (min-width:1060px) {
					right: -50px;
				}

				/*	@media (max-width:992px) {
					right: -6px;
				} */

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;
}

.owl-carousel.off {
	display: block;
}

.payment-view {
	margin-top: var(--page-margin-top);
	padding-top: 30px;
	padding-bottom: 60px;
}

.inner-banner {
	background-image: url(RESOURCE/img/banner-2.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 290px;
	position: relative;

	&.region-8156 {
		background-image: url(RESOURCE/img/dose-banner-2.jpg);
		background-position: 30% 76%;

		@media (max-width: 767px) {
			background-position: top;
		}
	}

	&.region-8161 {
		background-image: url(RESOURCE/img/brockeswalde-banner-1.jpg);
		background-position: center;

		@media (max-width: 767px) {
			background-position: 90% 0;
		}
	}

	&.region-8163 {
		background-image: url(RESOURCE/img/berensch-banner.jpg);
		background-position: top;

	}

	&.region-8157 {
		background-image: url(RESOURCE/img/grimmershoern-banner.jpg);
	}

	&.region-8155 {
		background-image: url(RESOURCE/img/duhnen-banner.jpg);
	}

	&.region-8160 {
		background-image: url(RESOURCE/img/lotsenviertel-banner.jpg);
	}

	&.region-8154 {
		background-image: url(RESOURCE/img/sahlenburg-banner.jpg);
	}

	&.region-8162 {
		background-image: url(RESOURCE/img/holtespangen-banner-1.png);
		background-position: 0 -20px;

		@media (max-width: 1350px) {
			background-position: center;
		}
	}


	@media (max-width:992px) {
		height: 220px;
	}


	.page-title {
		position: absolute;
		bottom: 20px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;
		text-align: right;

		@media (max-width:992px) {
			bottom: 30px;
		}


		.pag-title {
			display: inline-block;
			font-size: 36px;
			margin-bottom: 0;
			color: var(--color-white);
			text-transform: uppercase;
			padding: 0 10px;
			border-bottom: 1px solid var(--color-white);

			@media (max-width:992px) {
				font-size: 24px;
			}

			span {
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
			}
		}


	}
}

.regionen-v1 {
	.inner-banner {
		height: 350px;
	}
}



.wattwanderzeiten-v1 {
	.inner-banner {
		background-image: url(RESOURCE/img/wattwanderzeiten-banner-1.jpg);
		background-position: left;
	}
}

.cuxhaven-erleben-v1 {
	.inner-banner {
		background-image: url(RESOURCE/img/cuxhaven-erleben-banner-1.jpg);
		background-position: center bottom;
	}
}

.webcam-v1 {
	.inner-banner {
		background-image: url(RESOURCE/img/webcam-banner.jpg);
		background-position: 50% 60%;
	}
}

.impressum-v1 {
	.inner-banner {
		background-image: url(RESOURCE/img/impressum-banner.jpg);
		background-position: right;
	}
}

.contact-page-layout-v1 {
	.inner-banner {
		background-image: url(RESOURCE/img/kontakt-banner.jpg);
		background-position: center center;
		position: relative;

		&:after {
			display: block;
			content: " ";
			background: rgb(0 0 0 / 40%);
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			top: 0;
			left: 0;

		}


		.heading1 {
			@media (max-width: 480px) {
				font-size: 30px;
				padding: 0 0 10px 0;
			}
		}
	}
}


.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.googleMapsHint {
	color: var(--googlemaps-hint-text-color);
	font-size: var(--googlemaps-hint-font-size);
	width: 100%;
}

.no-google-map {
	width: 100%;
	height: 150px;
	position: relative;
}

.box {
	position: absolute;
	z-index: 999 !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		margin: 21px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 0px 10px 10px;
			max-width: 180px;
		}

	}
}

.datepicker-trigger {
	.asd__wrapper {
		@media (max-width: 480px) {
			margin: 0 auto;
		}
	}
}

/*@media (min-width:1200px) {
	.container {
		max-width: 1230px;
	}
}*/

@media (min-width:1500px) {
	.container {
		max-width: 1370px;
	}
}

.pswp__caption__center {
	text-align: center !important;
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}

.covid-section {

	margin: 0;
	position: absolute;
	top: 140px;
	right: 40px;
	z-index: 2;


	@media (max-width: 1140px) {
		right: 20px;
		top: 120px;
		bottom: inherit;
	}

	@media (max-width: 768px) {
		right: 20px;
		top: 160px;
		bottom: inherit;
	}

	@media (max-width: 560px) {
		right: 10px;
		top: 140px;
		bottom: inherit;
	}

	@media (max-width: 375px) {
		display: none;
	}


	.btn {
		width: 160px;
		height: 160px;
		border-radius: 100px;
		font-size: 14px;
		background: #1D8172;
		border: 0;

		@media (max-width: 768px) {
			width: 140px;
			height: 140px;
			font-size: 12px;
		}

		&:before {
			content: '';
			background: rgba(255, 255, 255, 0.2);
			position: absolute;
			border-radius: 50%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			animation: pulse 2s infinite;
		}

		span {
			font-size: 20px;
			color: var(--secondary-background-color);

		}

		/*	@media (max-width:1170px) {
			width: 130px;
			height: 130px;
			white-space: unset;
		}

		@media (max-width: 992px) {
			width: 110px;
			height: 110px;
			font-size: 14px;

		}

		@media (max-width: 768px) {
			width: 98px;
			height: 98px;
			font-size: 13px;

		}*/

		&:active,
		&:focus {
			background-color: var(--btn-primary-bg-color-hover) !important;
			border-color: var(--btn-primary-border-color-hover) !important;
		}

		.covid-btn {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
		}
	}


}

strong {
	font-weight: 800;
}

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
	width: 100%;
}

.table {
	color: var(--color-grey);
}

label {
	color: var(--color-grey);
}

.gray-bg {
	background: var(--gray-bg);
}

.blue-bg {
	background: var(--blue-bg);

	.color-secondary {
		color: var(--color-primary) !important;
	}

	p {
		color: var(--color-white);
	}
}

.apartment-north-sea {
	background: var(--color-secondary);
	color: var(--color-primary);

	p {
		font-size: 22px;
	}

	.heading1 {
		padding-left: 40px;
		padding-right: 40px;

		@media (max-width: 480px) {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}

	span {
		font-size: 30px;
	}

	p {
		color: var(--color-primary);
	}

	.smooch {
		font-family: 'Smooch-Regular';
		font-size: 60px;
		font-weight: 400;
		margin: 0 0 0 5px;

		@media (max-width: 767px) {
			font-size: 40px;
		}
	}
}

.Preisemodal {
	.modal-body {
		max-height: 70vh;
		overflow: auto;
	}
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

/*.vo-index-html {
	.heading1 {
		padding-left: 40px;
		padding-right: 40px;

		@media (max-width: 480px) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}*/

.cookie-dlg {
	.body {
		color: var(--color-primary);
		font-size: 18px !important;
	}
}

@keyframes pulse {

	0%,
	100% {
		animation-timing-function: ease-in
	}

	50% {
		transform: scale(1.2)
	}
}