.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/parallax-1.jpg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
		padding-left: 15px;
		padding-right: 15px;
	}

	.parallax-box {
		background-color: rgb(217, 195, 165, 0.8);
		padding: 50px 20px;
		min-height: auto;
		border-radius: 15px;
		box-shadow: 0 5px 20px rgb(0, 0, 0, 0.3);

		.fewo-rating-text {
			color: #fff;
			margin-bottom: 7px;
		}

		ul {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 5px 0;

			li {
				margin: 0 2px;
				color: #fff;
			}
		}

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-white);


			ul {
				li {
					color: #fff;
				}
			}
		}
	}

}