.card1 {
	.iconBox {
		position: absolute;
		top: 25px;
		left: 25px;
		background-color: #0663a2;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		font-size: 15px;
		color: #fff;
	}

	.fa-house {
		line-height: 1.7 !important;
		font-size: 22px !important;
		margin-left: 7px;
	}

	.fa-children {
		line-height: 1.7 !important;
		font-size: 23px !important;
		margin-left: 6px;
		color: #fff;
	}

	.fa-dog {
		line-height: 1.7 !important;
		font-size: 22px !important;
		margin-left: 7px;
	}


	.fa-spa {
		line-height: 1.7 !important;
		font-size: 22px !important;
		margin-left: 7px;
	}

	.fas,
	.fa-solid {
		font-weight: 900;
	}

	.fas,
	.fa-classic,
	.fa-solid,
	.far,
	.fa-regular {
		font-family: 'Font Awesome 6 Free';
	}

	.fa,
	.fa-classic,
	.fa-sharp,
	.fas,
	.fa-solid,
	.far,
	.fa-regular,
	.fab,
	.fa-brands {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: var(--fa-display, inline-block);
		font-style: normal;
		font-variant: normal;
		line-height: 1;
		text-rendering: auto;
	}

	.contentBox {
		padding: 10px 10px;
		width: calc(100% - 100px);
		position: relative;
		left: 50px;
		margin-top: -20%;
		background-color: #fff;
		box-shadow: 5px 10px 15px rgb(206, 206, 206);

		@media (min-width: 992px) {
			padding: 10px 10px;
			width: calc(100% - 100px);
			position: relative;
			left: 50px;
		}
	}

	.contentBox2 {
		padding: 10px 10px;
		width: calc(100% - 100px);
		position: relative;
		left: 50px;
		margin-top: -5%;
		background-color: #fff;
		box-shadow: 5px 10px 15px rgb(206, 206, 206);
	}

	.contentHeader {
		font-size: 23px;
		font-weight: 600;
	}

	.contentHinweis {
		font-size: 19px;
		color: #0971B7;
	}

	.contentText {
		margin-top: 10px;
		font-size: 18px;
		line-height: 1.75;
	}
}