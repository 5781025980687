.unit-pricelist-v1 {
	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;

				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
	}

	.nav-tabs {
		border-bottom: 0px solid #dee2e6;

		.nav-link {
			margin-right: 1px;
			background: var(--color-secondary-light);
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			padding: 10px 30px;

			&.active {
				background: transparent;
			}
		}
	}

}