.unit-card-v2 {

	position: relative;

	.owl-carousel {
		overflow: hidden;
		aspect-ratio: 16/9;

		.owl-nav {
			button {
				width: 40px;
				height: 40px;
				top: 38%;
				position: absolute;
				border-radius: 50%;
				cursor: pointer;
				opacity: 1;
				color: var(--color-white);
				text-align: center;
				background-color: rgba(112, 112, 113, 0.7) !important;
			}

			.owl-prev {
				left: 10px;

				span {
					font-weight: bold;
					font-size: 28px;
					line-height: 25px;
				}

			}

			.owl-next {
				right: 10px;

				span {
					font-weight: bold;
					font-size: 28px;
					line-height: 25px;
				}

			}
		}

		.owl-dots {
			position: absolute;
			width: 70%;
			bottom: 10px;
			padding: 0;
			margin: 0;
			list-style: none;
			text-align: center;
			line-height: 1;
			left: 50%;
			transform: translateX(-50%);

			.owl-dot {
				background-color: #fff;
				width: 6px !important;
				height: 6px !important;
				opacity: .25;
				margin: 0 1px !important;
				border-radius: 50%;

				&.active {
					opacity: 1;
				}
			}
		}
	}

	.content {
		padding: 20px 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border: 1px solid var(--color-grey-normal);

		/* @media (min-width:767px) {
			min-height: 270px;
		} */

		background-color: var(--white);

		.region-type {
			font-size: var(--font-size-md);
		}

		.fa {
			padding-right: 5px;
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		}

		.rating {
			min-height: 40px;

			.ratings {


				.fa {
					font-size: 18px;
					padding-right: 0px;
					color: #fed136;
				}
			}

			.count {
				font-size: 15px;
				margin-top: -5px;
			}
		}

		.properties {
			margin-top: 10px;
			margin-bottom: 10px;
		}



		.night-price {
			/*text-align: center;*/
			padding-top: 10px;
			padding-bottom: 10px;

			.price-start {
				text-align: right;
				font-size: 16px;

				strong {
					color: var(--color-primary);
					font-size: 18px;
					font-family: var(--font-family-title);

					@media(min-width: 1200px) {
						font-size: 22px;
					}
				}

			}

			.more-price-info {
				font-size: 16px;
			}

			a {
				color: #8c8c8c;
			}
		}

		.label-container {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;

			.label {
				padding: 5px 10px;
				color: white;
				border-radius: 5px;
				font-weight: 500;
				width: fit-content;
				font-weight: 500;
				line-height: 25px;
				font-size: var(--font-size-md);
			}

			.btn {
				font-size: var(--font-size-md);
			}

			.customer-rating {
				background: var(--color-secondary);
				font-weight: 500;
			}
		}


	}

	.comodities {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 15px 0;
	}


	.comodity {
		padding: 5px;
	}

	.image-wrap {
		position: relative;

		/*	&:hover {
			img {
				filter: brightness(0.7);
			}
		} */

		img {
			width: 100%;
			transition: 0.5s;

		}

		.stars {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: var(--color-stars);
			background-color: white;
			padding: 9px;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}

		.rules {
			position: absolute;
			bottom: 10px;
			left: 10px;
			background-color: white;
			padding: 5px;
			border-radius: 5px;
			color: var(--font-color-main);
			z-index: 2;
		}

		.favorite {
			position: absolute;
			top: 10px;
			right: 10px;
			color: white;
			font-size: 1.5em;
			z-index: 2;
		}
	}


	.ribbon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}

	.ribbon span {
		font-size: 13px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: var(--color-red);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		left: -21px;
	}


}

.popover {
	border-radius: 0;
	background-color: var(--color-white);
	border: 1px solid #C0C0C0;
	font-size: var(--font-size-md);
	box-shadow: inherit;
	width: 220px;

	.popover-header {
		border-radius: 0;
		background-color: var(--color-grey-light);
		margin-bottom: 10px;
		padding: 10px 10px;
		font-size: var(--font-size-md);
		font-weight: normal;
		display: none;
	}

	.arrow {
		&:after {
			border-top-color: #C0C0C0 !important;
		}
	}

	.popover-body {
		padding: 10px;
		font-size: 13px;

		.closepopover {
			text-align: center;
			cursor: pointer;
			padding-top: 10px;
			font-weight: 700;

			&:hover {
				text-decoration: underline;
			}
		}




		ul {
			overflow-y: scroll;
			height: 180px;
			padding-right: 10px;


			li {
				position: relative;
				margin-bottom: 2px;
				padding-left: 14px;
				list-style: none;
				font-size: var(--font-size-md);
				font-family: var(--font-family-main);
				margin-bottom: 3px;

				&:before {
					background-color: var(--color-primary);
					width: 5px;
					height: 5px;
					position: absolute;
					content: "";
					left: 0;
					top: 6px;
					border-radius: 50%;

				}
			}
		}
	}
}