.webcam-v1 {
	margin-top: var(--page-margin-top);

	.cuxhaven-box {
		.box-part {
			position: relative;
			margin-bottom: 30px;
			-webkit-transition: 1s;
			transition: 1s;
			cursor: pointer;

			&:hover {
				.content {
					height: 100%;

					p {
						opacity: 1;
					}
				}
			}

			img {
				width: 100%;
			}

			.content {
				background-color: var(--overlay-color);
				position: absolute;
				width: 100%;
				bottom: 0;
				padding: 10px 9px;
				height: 30%;
				-webkit-transition: 1s;
				transition: 1s;

				@media (max-width: 992px) {
					height: 100%;
				}

				@media all and (min-width: 993px) and (max-width: 1199px) {
					padding: 5px 9px;
				}

				h4 {
					margin: 0 0 3px 0;
					color: #fff;

					@media all and (min-width: 993px) and (max-width: 1199px) {
						font-size: 14px;
					}

				}

				p {
					margin: 0;
					color: #fff;
					font-size: 12px;
					opacity: 0;
					-webkit-transition: 1s;
					transition: 1s;

					@media (max-width: 992px) {
						opacity: 1;
					}
				}
			}
		}
	}

	.best-webcams {
		h3 {
			margin-bottom: 15px;
		}

		img {
			width: 100%;
		}
	}

}